import { GetServerSidePropsContext, GetStaticPropsContext } from 'next'
import { ParsedUrlQuery } from 'querystring'

import { SanityPrintShopType } from '@data/sanity/queries/types/shop'
import { defaultLocale, Locale } from './language'

export interface PageUrlOptions {
  newLocale?: Locale
  locale?: Locale
  parameters?: Record<string, string>
}

export enum PageType {
  PAGE = 'page',
  HOME_PAGE = 'homePage',
  SHOP_PAGE = 'shopPage',
  PRIVATE_SHOP_PAGE = 'privateShopPage',
  TEAM_SHOP_PAGE = 'teamShopPage',
  YEAR_SHOP_PAGE = 'yearShopPage',
  CART_PAGE = 'cartPage',
  SIGNUP_PAGE = 'signupPage',
  LOGIN_PAGE = 'loginPage',
  LOGOUT_PAGE = 'logoutPage',
  PASSWORD_RECOVERY_PAGE = 'passwordRecoveryPage',
  ACCOUNT_PAGE = 'accountPage',
  ACCOUNT_ADDRESS_PAGE = 'accountAddressPage',
  COLLECTION = 'collection',
  PRODUCT = 'product',
  SHOP_PRODUCT = 'shopProduct',
  SHOP_PRODUCT_PRINT = 'shopProductPrint',
  BLOG_PAGE = 'blogPage',
  BLOG_POST = 'blogPost',
  BLOG_CATEGORY = 'blogCategory',
  BLOG_AUTHOR = 'blogAuthor',
  CREATE_TEAM_SHOP_PAGE = 'createTeamShopTemplatePage',
  CREATE_YEAR_SHOP_PAGE = 'createYearShopTemplatePage',
  EDIT_TEAM_SHOP_PAGE = 'editTeamShopTemplatePage',
  EDIT_YEAR_SHOP_PAGE = 'editYearShopTemplatePage',
  SHOP = 'shop',
}

export interface CustomPreviewData {
  token?: string
}

export interface CustomStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale: Locale
  locales: Locale[]
  previewData?: CustomPreviewData
}

export interface CustomErrorStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale?: Locale
  locales: Locale[]
  previewData?: CustomPreviewData
}

export interface CustomServerSidePropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetServerSidePropsContext<Q> {
  locale: Locale
}

export interface StaticPathWithSlug {
  params: {
    slug: string
  }
  locale: Locale
}

export interface StaticPathWithSlugs {
  params: {
    slugs: string[]
  }
  locale: Locale
}

export interface StaticPathWithId {
  params: {
    id: string
  }
  locale: Locale
}

export type PageTypeValues<T> = {
  [K in PageType]: T
}

const pageRoutes: PageTypeValues<string[]> = {
  [PageType.HOME_PAGE]: [],
  [PageType.SHOP_PAGE]: ['shop'],
  [PageType.TEAM_SHOP_PAGE]: ['shop', SanityPrintShopType.TEAM, '[shop_id]'],
  [PageType.YEAR_SHOP_PAGE]: ['shop', SanityPrintShopType.YEAR, '[shop_id]'],
  [PageType.PRIVATE_SHOP_PAGE]: ['shop', '[shop_type]', '[shop_id]'],
  [PageType.CART_PAGE]: ['cart'],
  [PageType.PAGE]: ['[page_slug]'],
  [PageType.SIGNUP_PAGE]: ['signup'],
  [PageType.LOGIN_PAGE]: ['login'],
  [PageType.LOGOUT_PAGE]: ['api', 'auth', 'logout'],
  [PageType.PASSWORD_RECOVERY_PAGE]: ['password-recovery'],
  [PageType.ACCOUNT_PAGE]: ['account'],
  [PageType.ACCOUNT_ADDRESS_PAGE]: ['account', 'addresses'],
  [PageType.COLLECTION]: ['shop', '[collection_slug]'],
  [PageType.PRODUCT]: ['products', '[product_slug]'],
  [PageType.SHOP_PRODUCT]: [
    'shop',
    '[shop_type]',
    '[shop_id]',
    '[product_slug]',
  ],
  [PageType.SHOP_PRODUCT_PRINT]: [
    'shop',
    '[shop_type]',
    '[shop_id]',
    '[product_slug]',
    'print',
  ],
  [PageType.BLOG_PAGE]: ['blog'],
  [PageType.BLOG_POST]: ['blog', '[post_slug]'],
  [PageType.BLOG_CATEGORY]: ['blog', 'category', '[category_slug]'],
  [PageType.BLOG_AUTHOR]: ['blog', 'author', '[author_slug]'],
  [PageType.CREATE_TEAM_SHOP_PAGE]: [
    'shop',
    SanityPrintShopType.TEAM,
    'create',
  ],
  [PageType.CREATE_YEAR_SHOP_PAGE]: [
    'shop',
    SanityPrintShopType.YEAR,
    'create',
  ],
  [PageType.EDIT_TEAM_SHOP_PAGE]: [
    'shop',
    SanityPrintShopType.TEAM,
    '[shop_id]',
    'edit',
  ],
  [PageType.EDIT_YEAR_SHOP_PAGE]: [
    'shop',
    SanityPrintShopType.YEAR,
    '[shop_id]',
    'edit',
  ],
  [PageType.SHOP]: ['shop', '[shop_type]', '[shop_id]'],
}

/**
 * Gets relative page URL.
 */
export const getPageUrl = (
  pageType: PageType,
  slugs: string | string[] | null = null,
  options: PageUrlOptions = {}
) => {
  // Get URL locale prefix
  const localePrefix =
    options?.newLocale &&
    options.newLocale !== options?.locale &&
    options.newLocale !== defaultLocale
      ? `${options.newLocale}`
      : null

  // Get relative URL
  const urlSlugs = slugs && Array.isArray(slugs) ? slugs : [slugs]
  let slugIndex = 0
  const urlSegments =
    pageRoutes[pageType]?.map((segment) => {
      // Replace segment placeholder with a slug or an empty string
      if (/\[.*\]/.test(segment)) {
        slugIndex += 1
        return urlSlugs?.[slugIndex - 1] ?? ''
      }

      return segment
    }) ?? []
  const url = [localePrefix, ...urlSegments].filter(Boolean).join('/')

  // Get URL parameters
  const searchParams = new URLSearchParams()
  Object.entries(options?.parameters ?? {}).forEach((parameter) => {
    if (parameter[0] && parameter[1]) {
      searchParams.set(parameter[0], parameter[1])
    }
  })
  const parameters = searchParams.toString()

  const urlWithParameters = [url, parameters].filter(Boolean).join('?')
  return `/${urlWithParameters}`
}

const collectionPageTypes = [PageType.SHOP_PAGE, PageType.COLLECTION]

/**
 * Determines if page type has product collection.
 */
export const isRouteCollection = (pageType: PageType) =>
  collectionPageTypes.includes(pageType)
