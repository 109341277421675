import axios, { AxiosError, AxiosResponse } from 'axios'

import { SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  SanityPrintArea,
  SanityPrintSlotPermission,
  SanityPrintSlotType,
  SanityProductPrintConfiguration,
} from '@data/sanity/queries/types/print'
import { SanityProductGalleryPhoto } from '@data/sanity/queries/types/product'
import {
  SanityPrintColor,
  SanityPrintType,
  SanityPrintShopType,
  SanityShopPrint,
} from '@data/sanity/queries/types/shop'
import { filterDuplicates } from './helpers'
import { Locale } from './language'

export interface ImageWithAreas {
  image: SanityImageFragment
  slotType?: string
  areas?: SanityPrintArea[]
}

export interface ProductGalleryImageWithAreas {
  forOption: string
  imagesWithAreas: Array<ImageWithAreas>
}

interface ImagePrintConfiguration {
  slotType?: string
  areas?: SanityPrintArea[]
}

interface PrintAssetSection {
  type: SanityPrintType
  slotTypes: SanityPrintSlotType[]
  isOptimized: boolean
}

export interface ShopPrint {
  id: string
  image: SanityImageFragment
  filename: string
  slotTypes: SanityPrintSlotType[]
  type: SanityPrintType
  color?: SanityPrintColor
  title?: string
  isOptimized?: boolean
}

interface UploadPrintResponse {
  prints?: ShopPrint[]
  error?: string
}

interface UpdatePrintInput {
  shopId: string
  printId: string
  title: string
}

interface UpdatePrintResponse {
  error?: string
}

interface RemovePrintInput {
  shopId: string
  printId: string
}

interface RemovePrintResponse {
  error?: string
}

/**
 * Gets product gallery images with added slot types and print areas.
 */
export const getProductGalleryWithPrintAreas = (
  galleryPhotos?: SanityProductGalleryPhoto[],
  printConfiguration?: SanityProductPrintConfiguration[]
): ProductGalleryImageWithAreas[] => {
  if (!galleryPhotos) {
    return []
  }

  // Get master configuration items for each slot
  const masterPrintConfiguration: ImagePrintConfiguration[] =
    printConfiguration?.map(({ colorConfiguration, slot }) => ({
      slotType: slot.type,
      areas: colorConfiguration.find(({ isMaster }) => isMaster)?.printAreas,
    })) ?? []

  return galleryPhotos.map(({ forOption, photos }) => ({
    forOption: forOption,
    imagesWithAreas: photos.map((photo) => {
      const imageConfiguration: ImagePrintConfiguration =
        printConfiguration
          ?.map(({ colorConfiguration, slot }) => {
            const matchingColorConfiguration = colorConfiguration.find(
              ({ galleryItem, galleryPhoto }) =>
                galleryItem === forOption && galleryPhoto === photo.asset?._ref
            )

            // Check if photo has a custom print configuration
            if (
              matchingColorConfiguration &&
              !matchingColorConfiguration.isInheritedFromMaster
            ) {
              return {
                slotType: slot.type,
                areas: matchingColorConfiguration.printAreas,
              }
            }

            const matchingMasterPrintConfiguration =
              masterPrintConfiguration.find(
                ({ slotType }) => slotType === slot.type
              )

            // Check if photo has an inherited print configuration
            if (
              matchingMasterPrintConfiguration &&
              matchingColorConfiguration?.isInheritedFromMaster
            ) {
              return {
                slotType: slot.type,
                areas: matchingMasterPrintConfiguration.areas,
              }
            }
          })
          ?.filter(Boolean)?.[0] ?? {}

      return {
        ...imageConfiguration,
        image: photo,
      }
    }),
  }))
}

/**
 * Gets all slots types for given print from an array of prints.
 */
const getAllPrintSlotTypes = (
  prints: SanityShopPrint[],
  print: SanityShopPrint
) =>
  prints
    .filter((subPrint) => subPrint.printImage?.id === print.printImage?.id)
    .map((subPrint) => subPrint.slot?.type)
    .filter(Boolean)
    .filter((slotType, index, array) =>
      filterDuplicates(slotType, index, array)
    )

/**
 * Parses Sanity shop prints.
 */
export const parseShopPrints = (prints?: SanityShopPrint[]) =>
  prints
    ?.filter((print) => !!print.printImage)
    ?.map((print) => {
      const image = print.printImage as SanityImageFragment
      const shopPrint: ShopPrint = {
        id: print._key,
        image,
        filename: image.filename,
        slotTypes: getAllPrintSlotTypes(prints ?? [], print),
        type: print.type,
        color: print.printColor,
        title: print.title,
        isOptimized: print.isOptimized,
      }

      return shopPrint
    })
    // Filter duplicate print asset IDs
    ?.filter(
      (shopPrint, index, array) =>
        array.findIndex(
          (arrayItem) => arrayItem.image.id === shopPrint.image.id
        ) === index
    )

/**
 * Gets print asset sections based on shop type and print permissions.
 */
export const getPrintAssetSections = (
  shopType: SanityPrintShopType,
  printPermissions: SanityPrintSlotPermission[]
) => {
  const printAssetSections: PrintAssetSection[] = []

  const logoSlotTypes = printPermissions
    .filter((printPermission) => printPermission.logo)
    .map((printPermission) => printPermission.slot.type)
  const imageSlotTypes = printPermissions
    .filter((printPermission) => printPermission.image)
    .map((printPermission) => printPermission.slot.type)

  if (logoSlotTypes.length > 0) {
    // Add a logo section to both shop types
    printAssetSections.push({
      type: SanityPrintType.LOGO,
      slotTypes: logoSlotTypes,
      isOptimized: shopType === SanityPrintShopType.YEAR,
    })
  }

  if (imageSlotTypes.length > 0) {
    // Add different image sections for shop types
    if (shopType === SanityPrintShopType.TEAM) {
      printAssetSections.push({
        type: SanityPrintType.IMAGE,
        slotTypes: imageSlotTypes,
        isOptimized: false,
      })
    }

    printAssetSections.push({
      type: SanityPrintType.IMAGE,
      slotTypes: imageSlotTypes,
      isOptimized: true,
    })
  }

  return printAssetSections
}

// Max print upload size: 5MB
export const maxPrintFileSize = 5 * 1024 * 1024

/**
 * Uploads shop prints.
 */
export const uploadPrints = async (
  locale: Locale,
  formData: FormData,
  genericErrorMessage?: string,
  fileSizeErrorMessage?: string
) => {
  try {
    const uploadPrintResponse = await axios.post<
      UploadPrintResponse,
      AxiosResponse<UploadPrintResponse>,
      FormData
    >('/api/shop/upload-print', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Locale': locale,
      },
    })

    return {
      prints: uploadPrintResponse.data.prints ?? [],
      errorMessage: uploadPrintResponse.data.error,
    }
  } catch (e) {
    if ((e as AxiosError).response?.status == 413) {
      return {
        prints: [],
        errorMessage: fileSizeErrorMessage,
      }
    }

    return {
      prints: [],
      errorMessage: genericErrorMessage,
    }
  }
}

/**
 * Updates a shop print title.
 */
export const updatePrintTitle = async (
  locale: Locale,
  shopId: string,
  printId: string,
  title: string
) => {
  try {
    await axios.post<
      UpdatePrintResponse,
      AxiosResponse<UpdatePrintResponse>,
      UpdatePrintInput
    >(
      '/api/shop/update-print',
      {
        shopId,
        printId,
        title,
      },
      {
        headers: {
          'X-Locale': locale,
        },
      }
    )

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

/**
 * Removes a shop print and all other shop prints containing the same asset.
 */
export const removePrint = async (
  locale: Locale,
  shopId: string,
  printId: string
) => {
  try {
    await axios.post<
      RemovePrintResponse,
      AxiosResponse<RemovePrintResponse>,
      RemovePrintInput
    >(
      '/api/shop/remove-print',
      {
        shopId,
        printId,
      },
      {
        headers: {
          'X-Locale': locale,
        },
      }
    )

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
