import groq from 'groq'

export const linkPageFragment = groq`
  "id": _id,
  "type": _type,
  "slug": slug.current,
  "shopType": type,
  "shopId": id
`

export const linkFragment = groq`
  _key,
  _type,
  title,
  url,
  _type == 'navLanguageSwitch' => {
    displayCurrency
  },
  _type == 'navAccountButton' => {
    text,
    displayIcon
  },
  "page": page->{
    ${linkPageFragment}
  }
`
