type ColorClassMap = Record<string, string>

const colorClasses: ColorClassMap = {
  white: 'text-white',
  black: 'text-black',
  red: 'text-red',
  'dark-red': 'text-red-dark',
  blue: 'text-blue',
  'dark-blue': 'text-blue-dark',
}

interface ColoredTextProps {
  mark: Record<string, string>
  children: string[]
}

const ColoredText = ({ mark, children }: ColoredTextProps) => {
  return <span className={colorClasses[mark.color]}>{children[0]}</span>
}

ColoredText.displayName = 'ColoredText'

export default ColoredText
