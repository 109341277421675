import cx from 'classnames'
import { useContext } from 'react'

import { useGetFormattedPrice } from '@lib/helpers'
import { StringsContext } from '@lib/strings'

interface ProductPriceProps {
  price: number
  comparePrice?: number
  inProductCard?: boolean
  inProductHero?: boolean
  inCartItem?: boolean
  showFromLabel?: boolean
  className?: string
}

const ProductPrice = ({
  price,
  comparePrice,
  inProductCard,
  inProductHero,
  inCartItem,
  showFromLabel,
  className,
}: ProductPriceProps) => {
  const strings = useContext(StringsContext)

  const getFormattedPrice = useGetFormattedPrice()

  return (
    <div
      className={cx(
        'flex',
        {
          'flex-col items-end': inProductHero,
          'items-center': inProductCard,
        },
        className
      )}
    >
      <div>
        <span
          className={cx({
            'sm:text-xl sm:leading-normal': inProductCard,
            'text-4xl leading-normal font-bold': inProductHero,
            'text-xl font-semibold sm:leading-normal': inCartItem,
            'text-red-light': !!comparePrice,
          })}
        >
          {showFromLabel && `${strings.productPriceFromLabel} `}
          {getFormattedPrice(price)}
        </span>

        {!!comparePrice && (
          <span
            className={cx('ml-1 text-gray-medium line-through', {
              'sm:text-xl sm:leading-normal': inProductCard,
              'text-3xl leading-normal': inProductHero,
              'text-xl font-semibold sm:leading-normal': inCartItem,
            })}
          >
            {showFromLabel && `${strings.productPriceFromLabel} `}
            {getFormattedPrice(comparePrice)}
          </span>
        )}
      </div>

      {/* Discount badge */}
      {!!comparePrice && (
        <span
          className={cx({
            'p-1 rounded-sm bg-pageText text-pageBG text-xxs leading-tight tracking-widest font-semibold uppercase text-center':
              inProductCard || inProductHero,
            'ml-2': inProductCard,
          })}
        >
          {strings.productDiscountText.replace(
            /{percent}/gi,
            Math.ceil(((comparePrice - price) / comparePrice) * 100).toString()
          )}
        </span>
      )}
    </div>
  )
}

export default ProductPrice
