import {
  CreateTeamShopTemplatePage,
  CreateYearShopTemplatePage,
  EditTeamShopTemplatePage,
  EditYearShopTemplatePage,
  Shop,
  ShopPrint,
  TeamShopTemplatePage,
  YearShopTemplatePage,
} from '@data/sanity/schema'
import { Keyed } from '@lib/helpers'
import { Locale } from '@lib/language'
import { SanityContentFragment } from './content'
import { SanityFilter, SanitySort } from './filter'
import { SanityImageFragment, SanityImageReference } from './image'
import { HasContent } from './page'
import { SanityPrintPermissions, SanityPrintSlot } from './print'
import { SanityProductFragment, SanityProductId } from './product'
import { SanitySchoolItem } from './school'
import { SanitySiteFragment } from './site'
import { HasCollectionStrings } from './strings'

export enum SanityPrintShopType {
  YEAR = 'year',
  TEAM = 'team',
}

export enum SanityPrintType {
  LOGO = 'logo',
  IMAGE = 'image',
}

export enum SanityPrintColor {
  LIGHT = 'light',
  DARK = 'dark',
}

export type SanityShopPrint = Keyed<
  Pick<ShopPrint, 'title' | 'printSku'> & {
    slot: SanityPrintSlot
    type: SanityPrintType
    printColor?: SanityPrintColor
    printImage?: SanityImageFragment
    isOptimized?: boolean
  }
>

export type SanityAllPrintShopIds = Array<Pick<Shop, 'id' | 'locale'>>

type SanityYearShopTemplatePage = Pick<
  YearShopTemplatePage,
  'title' | 'hasTransparentHeader' | 'seo' | 'noIndex'
> & {
  description: Array<SanityContentFragment>
  featuredProductIds?: Array<SanityProductId>
} & HasContent

type SanityTeamShopTemplatePage = Pick<
  TeamShopTemplatePage,
  'title' | 'hasTransparentHeader' | 'seo' | 'noIndex'
> & {
  description: Array<SanityContentFragment>
  featuredProductIds?: Array<SanityProductId>
} & HasContent

export type SanityPrintShopBase = Pick<Shop, 'id' | 'name' | 'active'> & {
  type: SanityPrintShopType
}

export type SanityPrintShop = Pick<Shop, 'userEmail'> &
  SanityPrintShopBase & {
    shopId: string
    school: SanitySchoolItem
    prints: Array<SanityShopPrint>
  }

export type SanityPrintShopPage = SanityPrintShop & {
  template: SanityYearShopTemplatePage | SanityTeamShopTemplatePage
  products: Array<SanityProductFragment>
  paginationLimit: number
  filter?: SanityFilter
  sort?: SanitySort
} & HasCollectionStrings

export interface SanityPrintShopPageQuery {
  page: SanityPrintShopPage
  site: SanitySiteFragment
}

export type SanityCreateTeamShopPage = Pick<
  CreateTeamShopTemplatePage,
  'hasTransparentHeader' | 'seo' | 'noIndex'
> &
  HasContent & {
    printPermissions: SanityPrintPermissions
  }

export interface SanityCreateTeamShopPageQuery {
  page: SanityCreateTeamShopPage
  site: SanitySiteFragment
}

export type SanityCreateYearShopPage = Pick<
  CreateYearShopTemplatePage,
  'hasTransparentHeader' | 'seo' | 'noIndex'
> &
  HasContent & {
    printPermissions: SanityPrintPermissions
  }

export interface SanityCreateYearShopPageQuery {
  page: SanityCreateYearShopPage
  site: SanitySiteFragment
}

export type SanityEditTeamShopPage = Pick<
  EditTeamShopTemplatePage,
  'hasTransparentHeader' | 'seo' | 'noIndex'
> &
  HasContent & {
    printPermissions: SanityPrintPermissions
    shop?: SanityPrintShop
  }

export interface SanityEditTeamShopPageQuery {
  page: SanityEditTeamShopPage
  site: SanitySiteFragment
}

export type SanityEditYearShopPage = Pick<
  EditYearShopTemplatePage,
  'hasTransparentHeader' | 'seo' | 'noIndex'
> &
  HasContent & {
    printPermissions: SanityPrintPermissions
    shop?: SanityPrintShop
  }

export interface SanityEditYearShopPageQuery {
  page: SanityEditYearShopPage
  site: SanitySiteFragment
}

export interface SanityUserShop {
  shopId: string
  id: string
  userEmail: string
  type: SanityPrintShopType
  name: string
  schoolId: string
  date: string
  userPhoneNumber?: string
}

export type SanityUserShopsQuery = SanityUserShop[]

export interface SanityMinimalShop {
  shopId: string
  prints: Array<SanityShopPrint>
}

export type SanityShopInput = Pick<Shop, '_type'> & {
  id: string
  active: true
  name: string
  type: SanityPrintShopType
  school: {
    _type: 'reference'
    _ref: string
  }
  userEmail: string
  userPhoneNumber?: string
  prints: Array<SanityShopPrint>
  locale: Locale
}

export type SanityShopPrintInput = Pick<ShopPrint, '_type'> & {
  _key: string
  print: SanityImageReference
  slot: {
    _type: 'reference'
    _ref: string
  }
  type: SanityPrintType
  title?: string
  printColor?: SanityPrintColor
  isOptimized?: boolean
}

export type SanityShopByIdQuery = SanityMinimalShop | null
