type ScreenSizeName = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const screenSizeNames: ScreenSizeName[] = ['xs', 'sm', 'md', 'lg', 'xl']

const range = (start: number, end: number) =>
  Array(end)
    .fill(undefined)
    .map((_x, i) => `${start + i}`)

const generateClasses = (
  property: string,
  values: string[],
  responsive: boolean = true
) => {
  const classes: string[] = []

  for (const value of values) {
    const newClass = `${property}-${value}`
    classes.push(newClass)

    if (responsive) {
      for (const screenSizeName of screenSizeNames) {
        classes.push(`${screenSizeName}:${newClass}`)
      }
    }
  }

  return classes
}

export const safelist: string[] = [
  ...generateClasses('text', ['left', 'center', 'right', 'justify']),
  ...generateClasses('animate', ['none', 'spin', 'ping', 'pulse', 'bounce']),
  ...generateClasses('grid-cols', [...range(1, 12), 'none']),
  ...generateClasses('col-span', [...range(1, 12), 'full']),
  ...generateClasses('col-start', [...range(1, 13), 'auto']),
  ...generateClasses('order', [...range(1, 12), 'first', 'last', 'none']),
  ...generateClasses('self', ['auto', 'start', 'end', 'center', 'stretch']),
  ...generateClasses('justify-self', [
    'auto',
    'start',
    'end',
    'center',
    'stretch',
  ]),
  ...generateClasses('max-w', [
    '0',
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7x',
    'full',
    'min',
    'max',
    'prose',
    'screen-sm',
    'screen-md',
    'screen-lg',
    'screen-xl',
    'screen-2xl',
  ]),
  ...generateClasses('min-h', ['screen', 'xs', 'sm', 'md', 'lg', 'xl']),
  ...generateClasses('pt', [
    '[100%]',
    '[calc((3/4)*100%)]',
    '[calc((7/5)*100%)]',
    '[calc((6/4)*100%)]',
    '[calc((9/16)*100%)]',
    '[calc((7/16)*100%)]',
  ]),
]

export const screens: Record<ScreenSizeName, string> = {
  xs: '480px',
  sm: '768px',
  md: '940px',
  lg: '1200px',
  xl: '1600px',
}

const internalColors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#121212',
  white: '#FFFFFF',
  gray: {
    light: '#CBCBCB',
    DEFAULT: '#BABABA',
    medium: '#B2B2B2',
    dark: '#A5A5A5',
  },
  red: {
    light: '#D93232',
    DEFAULT: '#A3181E',
    dark: '#731216',
  },
  blue: {
    DEFAULT: '#3E5B87',
    dark: '#09104B',
  },
  gradient: {
    from: '#3E5B87',
    to: '#09104B',
  },
  yellow: {
    light: '#FFFCB6',
  },
}

export const colors = {
  ...internalColors,
  pageBG: internalColors.white,
  pageText: internalColors.black,
  header: {
    bg: internalColors.white,
    text: internalColors.black,
  },
  box: {
    bg: internalColors.black,
    border: internalColors.black,
  },
  btn: {
    primary: {
      text: {
        DEFAULT: internalColors.white,
        hover: internalColors.white,
      },
      bg: {
        DEFAULT: internalColors.black,
        hover: internalColors.black,
      },
      border: {
        DEFAULT: internalColors.transparent,
        hover: internalColors.transparent,
      },
    },
    secondary: {
      text: {
        DEFAULT: internalColors.black,
        hover: internalColors.white,
      },
      bg: {
        DEFAULT: internalColors.transparent,
        hover: internalColors.black,
      },
      border: {
        DEFAULT: internalColors.black,
        hover: internalColors.black,
      },
    },
  },
  input: {
    border: internalColors.black,
    text: internalColors.black,
    bg: internalColors.transparent,
    placeholder: 'rgba(0, 0, 0, 0.4)',
    inverted: {
      border: internalColors.white,
      text: internalColors.white,
      bg: internalColors.transparent,
      placeholder: 'rgba(255, 255, 255, 0.4)',
    },
  },
  success: {
    DEFAULT: '#45BF1A',
  },
  error: {
    DEFAULT: internalColors.red.DEFAULT,
  },
}
